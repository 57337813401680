import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Dashboard from './Dashboard';
import Order from './Order';
import Admin from './Admin';
import NotFound from './NotFound';
import ProtectedRoute from './ProtectedRoute';
import Layout from './Layout';
import Points from './Points';
import AdminPoints from './AdminPoints';
import AdminIndexFeeSales from './AdminIndexFeeSales';
import TableMenu from './TableMenu';
import Brosur from './Brosur';
import AdminUserSummary from './AdminUserSummary';
import ChatAssistant from './ChatAssistant';



import { useAuth } from './hooks/useAuth';

function App() {
    const { isAuthenticated } = useAuth();

    return (
        <Router>
            <Routes>
                {/* Redirect based on authentication */}
                <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />} />

                {/* Public Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Protected Routes */}
                <Route 
                    path="/dashboard" 
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Dashboard />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />
               
<Route 
    path="/chat-assistant" 
    element={
        <ProtectedRoute>
            <Layout>
                <ChatAssistant />
            </Layout>
        </ProtectedRoute>
    } 
/>
                <Route 
                    path="/points" 
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Points />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/order" 
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Order />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/admin" 
                    element={
                        <ProtectedRoute requiredRole="admin">
                            <Layout>
                                <Admin />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/admin/points" 
                    element={
                        <ProtectedRoute requiredRole="admin">
                            <Layout>
                                <AdminPoints />
                            </Layout>
                        </ProtectedRoute>
                    }  
                />
                <Route 
                    path="/admin/user-summary" 
                    element={
                        <ProtectedRoute requiredRole="admin">
                            <Layout>
                                <AdminUserSummary />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/admin/index-fee-sales" 
                    element={
                        <ProtectedRoute requiredRole="admin">
                            <Layout>
                                <AdminIndexFeeSales />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/table-menu" 
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <TableMenu />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/brosur" 
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Brosur />
                            </Layout>
                        </ProtectedRoute>
                    } 
                />

                {/* Catch-all for undefined routes */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default App;
