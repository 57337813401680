import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
    List, ListItem, ListItemIcon, ListItemText, 
    Drawer, Toolbar, ButtonBase, Dialog, DialogTitle, 
    DialogContent, DialogContentText, DialogActions, 
    Button, Box 
} from '@mui/material';
import { 
    Dashboard as DashboardIcon,Savings as SavingsIcon, List as ListIcon, 
    Logout as LogoutIcon,Assistant as AssistantIcon, AdminPanelSettings as AdminIcon, 
    AttachMoney as FeeIcon, TableChart as TableIcon, 
    ContactMail as ContactIcon, Description as BrosurIcon
} from '@mui/icons-material';

const drawerWidth = 240;

const Sidebar = () => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const isAdmin = role === 'admin';
    const [contactOpen, setContactOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        navigate('/login');
    };

    const handleContactOpen = () => {
        setContactOpen(true);
    };

    const handleContactClose = () => {
        setContactOpen(false);
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ flexGrow: 1 }}>
                <List>
                    <ListItem>
                        <ButtonBase component={Link} to="/dashboard" sx={{ width: '100%' }}>
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ButtonBase>
                    </ListItem>

    <ListItem>
                        <ButtonBase component={Link} to="/chat-assistant" sx={{ width: '100%' }}>
                            <ListItemIcon><AssistantIcon /></ListItemIcon>
                            <ListItemText primary="AI Assistant" />
                        </ButtonBase>
                    </ListItem>
                    <ListItem>
                        <ButtonBase component={Link} to="/order" sx={{ width: '100%' }}>
                            <ListItemIcon><ListIcon /></ListItemIcon>
                            <ListItemText primary="Leads" />
                        </ButtonBase>
                    </ListItem>
                    <ListItem>
                        <ButtonBase component={Link} to="/points" sx={{ width: '100%' }}>
                            <ListItemIcon><SavingsIcon /></ListItemIcon>
                            <ListItemText primary="Points" />
                        </ButtonBase>
                    </ListItem>
                    <ListItem>
                        <ButtonBase component={Link} to="/table-menu" sx={{ width: '100%' }}>
                            <ListItemIcon><TableIcon /></ListItemIcon>
                            <ListItemText primary="Tabel Fee Sales" />
                        </ButtonBase>
                    </ListItem>
                    <ListItem>
                        <ButtonBase component={Link} to="/brosur" sx={{ width: '100%' }}>
                            <ListItemIcon><BrosurIcon /></ListItemIcon>
                            <ListItemText primary="Brosur" />
                        </ButtonBase>
                    </ListItem>
                    {isAdmin && (
                        <>
                            <ListItem>
                                <ButtonBase component={Link} to="/admin" sx={{ width: '100%' }}>
                                    <ListItemIcon><AdminIcon /></ListItemIcon>
                                    <ListItemText primary="Admin" />
                                </ButtonBase>
                            </ListItem>
                            <ListItem>
                                <ButtonBase component={Link} to="/admin/points" sx={{ width: '100%' }}>
                                    <ListItemIcon><AdminIcon /></ListItemIcon>
                                    <ListItemText primary="Admin Points" />
                                </ButtonBase>
                            </ListItem>
                            <ListItem>
                                <ButtonBase component={Link} to="/admin/user-summary" sx={{ width: '100%' }}>
                                    <ListItemIcon><AdminIcon /></ListItemIcon>
                                    <ListItemText primary="Leaderboard" />
                                </ButtonBase>
                            </ListItem>
                            <ListItem>
                                <ButtonBase component={Link} to="/admin/index-fee-sales" sx={{ width: '100%' }}>
                                    <ListItemIcon><FeeIcon /></ListItemIcon>
                                    <ListItemText primary="Index Fee Sales" />
                                </ButtonBase>
                            </ListItem>
                        </>
                    )}
                </List>
            </Box>
            <Box sx={{ mb: 2 }}>
                <List>
                    <ListItem button onClick={handleContactOpen}>
                        <ListItemIcon><ContactIcon /></ListItemIcon>
                        <ListItemText primary="Contact Admin" />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon><LogoutIcon /></ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Box>

            <Dialog open={contactOpen} onClose={handleContactClose}>
                <DialogTitle>Contact Admin</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        WA: 08112321777
                    </DialogContentText>
                    <DialogContentText>
                        Telegram: @fachmiagung
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleContactClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Drawer>
    );
};

export default Sidebar;
