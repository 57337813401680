import React, { useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Typography,
    Container,
    CssBaseline,
    Modal,
    TextField,
    Snackbar,
    Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from './indibiz-logo.png';
import Banner from './Hero4.png';
import AboutImage from './indibiz-140824-07.jpg';

const apiUrl = process.env.REACT_APP_API_URL;

const LoginPage = () => {
    const [openLogin, setOpenLogin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const navigate = useNavigate();

    const handleOpenLogin = () => setOpenLogin(true);
    const handleCloseLogin = () => setOpenLogin(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${apiUrl}/login`, { email, password });
            const { token, refreshToken, role } = res.data; // Assume backend returns refreshToken
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('role', role);
            setMessage('Login successful! Redirecting to dashboard...');
            setSuccessMessage(true);
            setTimeout(() => {
                navigate('/dashboard');
            }, 2000);
        } catch (err) {
            console.error('Login failed:', err);
            setMessage('Login failed. Please check your email and password.');
            setErrorMessage(true);
        }
    };
    

    const handleCloseSnackbar = () => {
        setSuccessMessage(false);
        setErrorMessage(false);
    };

    return (
        <Container component="main" maxWidth="xl" disableGutters>
            <CssBaseline />
            
            {/* Header Section */}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: { xs: '10px 20px', md: '20px 40px' },
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #e0e0e0',
                    zIndex: 10,
                }}
            >
                <img src={Logo} alt="Indibiz Logo" style={{ width: '120px' }} />
                <Box>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        onClick={() => navigate('/register')}
                        sx={{ marginRight: 2, fontSize: { xs: '10px', md: '16px' } }}
                    >
                        Daftar Sekarang
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#000', borderColor: '#000' }}
                        onClick={handleOpenLogin}
                        sx={{ fontSize: { xs: '10px', md: '16px' } }}
                    >
                        Masuk
                    </Button>
                </Box>
            </Box>

            {/* Main Content Section */}
<Box
    sx={{
        position: 'relative',
        backgroundImage: `url(${Banner})`,
        backgroundSize: 'cover', // Ensure the image covers the area without repeating
        backgroundRepeat: 'no-repeat', // Prevent the image from repeating
        backgroundPosition: 'center', // Center the image
        minHeight: { xs: '40vh', md: '60vh' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: { xs: '0 20px', md: '0 40px' },
    }}
>
    {/* Overlay Text and Button */}
    <Box
        sx={{
            maxWidth: { xs: '100%', md: '400px' },
            color: '#000',
            textAlign: 'left',
        }}
    >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, fontSize: { xs: '1.2rem', md: '2rem' } }}>
            Selamat datang di Unorganic Leads Platforms Witel Bekasi Karawang.
        </Typography>
        <Button
            variant="contained"
            style={{ backgroundColor: '#000', color: '#fff' }}
            sx={{
                marginTop: '20px',
                padding: { xs: '8px 16px', md: '10px 20px' },
                fontSize: { xs: '10px', md: '16px' },
                textAlign: 'left'
            }}
            onClick={() => navigate('/register')}
        >
            Mendaftar
        </Button>
    </Box>
</Box>


            {/* About Section */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    padding: '40px 20px',
                    backgroundColor: '#f5f5f5',
                    textAlign: 'left',
                }}
            >
                <Box sx={{ width: { xs: '100%', md: '45%' }, mb: { xs: 3, md: 0 }, textAlign: 'center' }}>
                    <img
                        src={AboutImage}
                        alt="About Sobat Indibiz"
                        style={{
                            width: '100%',
                            borderRadius: '8px',
                        }}
                    />
                </Box>
                <Box sx={{ width: { xs: '100%', md: '45%' } }}>
                    <Typography variant="h6" sx={{ color: '#000', padding: '10px', fontSize: '1rem' }}>
                        Tentang ULP
                    </Typography>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, padding: '10px', color: '#000', fontSize: { xs: '1.5rem', md: '2rem' } }}>
                        Wadah Channel Unorganic Witel Bekasi Karawang
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#000', padding: '10px', fontSize: '1rem', lineHeight: '1.6' }}>
                        ULP adalah sebuah AI Assisted Paltform  yang dirancang untuk mewadahi informasi leads kebutuhan B2B dari unorganic channel seperti Teknisi dan Sales IndiHome.
                    </Typography>
                </Box>
            </Box>

            {/* Modal for Login Form */}
            <Modal
                open={openLogin}
                onClose={handleCloseLogin}
                aria-labelledby="login-modal-title"
                aria-describedby="login-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', md: 400 },
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <Typography id="login-modal-title" variant="h6" component="h2">
                        Sign In
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Email Address"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            style={{ backgroundColor: '#000', color: '#fff' }}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Footer Section */}
            <Box
                sx={{
                    backgroundColor: '#000',
                    color: '#fff',
                    padding: '20px 40px',
                    textAlign: 'center',
                    marginTop: '40px',
                }}
            >
                <Typography variant="body2" sx={{ fontSize: '14px' }}>
                    &copy; {new Date().getFullYear()} Sobat IndiBiz.
                </Typography>
                <Box sx={{ marginTop: '10px' }}>
                    <Button
                        sx={{ color: '#fff', textTransform: 'none' }}
                        onClick={() => navigate('/contact-us')}
                    >
                        Contact Us
                    </Button>
                </Box>
            </Box>

            {/* Snackbar Notifications */}
            <Snackbar
                open={successMessage}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorMessage}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default LoginPage;
